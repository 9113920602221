import { connect } from "react-redux";
import "./work.scss";
import NavTab from "../components/NavTab";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { Col, Divider, Row, Typography } from "antd";
import Foot from "../footer/Foot";

const { Title } = Typography;

const Work = () => {
  return (
    <Layout>
      <Header>
        <NavTab selected="6" />
      </Header>
      <Content
        style={{ background: "#fbfbfb", minHeight: "80vh", columnGap: "10px" }}
      >
        <Row justify="space-around" align="middle">
          <Col
            className="sas"
            style={{
              verticalAlign: "middle",
              background: "#fbfbfb",
            }}
          >
            <img
              style={{ display: "block", margin: "20px auto", width: "12%" }}
              src="icons/super.png"
            />
            <Divider type="horizontal" />
          </Col>
        </Row>

        <Row justify="space-around" align="middle">
          <Col
            className="sas"
            style={{
              background: "#CAC7C6",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            <Title level={3}>
              ¿Quieres trabajar con nosotros?, puedes enviarnos tu curriculum
              por
              <a
                style={{
                  color: "#111",
                  marginLeft: "6px",
                  textDecoration: "underline orange",
                }}
                href="mailto:info@sasseguridad.com.ar"
                target="_blank"
              >
                correo
              </a>
            </Title>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
};

export default connect()(Work);
