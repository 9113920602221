
import { createStore } from 'redux';

const initialState = {
    guid: "",
    login: false,
    data: {}
}


const reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'LOGIN':
            return {
                login: true
            };

        default:
            return state;
    }

}

const store = createStore(reducer);

export default store;