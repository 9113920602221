import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  SafetyOutlined,
  TeamOutlined,
  ShareAltOutlined,
  NodeIndexOutlined,
  SolutionOutlined,
  ContactsOutlined,
} from "@ant-design/icons";

import "./navtab.scss";

const NavTab = ({ selected }) => {
  return (
    <>
      <div className="logo">
        <img src="icons/logo.png" alt="SAS" />
      </div>

      <Menu selectedKeys={selected} theme="dark" mode="horizontal">
        <Menu.Item key="1" icon={<SafetyOutlined />}>
          Nosotros
          <Link to="/about" />
        </Menu.Item>
        <Menu.Item key="2" icon={<TeamOutlined />}>
          Nuestro equipo <Link to="/people" />
        </Menu.Item>
        <Menu.Item key="3" icon={<ShareAltOutlined />}>
          Nuestros clientes <Link to="/customers" />
        </Menu.Item>
        <Menu.Item key="4" icon={<NodeIndexOutlined />}>
          Servicios <Link to="/services" />
        </Menu.Item>
        <Menu.Item key="5" icon={<SolutionOutlined />}>
          Contacto <Link to="/contact" />
        </Menu.Item>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
export default connect(mapStateToProps)(NavTab);
