import { connect } from "react-redux";
import "./services.scss";
import NavTab from "../components/NavTab";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import Foot from "../footer/Foot";
import { UpSquareOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Services = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const element = document.getElementById(e.target.dataset.id);
    if (element) element.scrollIntoView();
  };

  return (
    <Layout>
      <Header>
        <NavTab selected="4" />
      </Header>
      <Content style={{ background: "#FFF" }}>
        <Row justify="space-around" align="middle">
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Button data-id="a1" onClick={handleClick}>
              Barrios
            </Button>
            <Button data-id="a2" onClick={handleClick}>
              Industrias
            </Button>
            <Button data-id="a3" onClick={handleClick}>
              Rural
            </Button>
            <Button data-id="a4" onClick={handleClick}>
              Capacitaciones
            </Button>
            <Button data-id="a5" onClick={handleClick}>
              Eventos especiales
            </Button>
            <Button data-id="a6" onClick={handleClick}>
              Custodia y acompañamientos
            </Button>
            <Button data-id="a7" onClick={handleClick}>
              Investigaciones
            </Button>
            <Button data-id="a8" onClick={handleClick}>
              Sistemas tecnológicos
            </Button>
            <Button data-id="a9" onClick={handleClick}>
              Asesoramiento y auditorias
            </Button>
          </Col>
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Title id="a1" type="warning" level={3}>
              BARRIOS
            </Title>
            <Title level={5}>CERRADOS, ABIERTOS, COUNTRIES, EDIFICIOS</Title>
            <Title type="secondary" level={5}>
              Control de ingresos, egresos. <br />
              Recorridos perimetrales (con seguimiento satelital), con vehículos
              adaptados al terreno en caso de ser necesario. <br />
              Control por cámaras y los últimos métodos tecnológicos.
            </Title>
            <Divider type="horizontal" />
            <Title id="a2" type="warning" level={3}>
              INDUSTRIAS
            </Title>
            <Title type="secondary" level={5}>
              Control de ingresos, egresos. <br />
              Controles perimetrales con o sin vehículos adaptados. <br />
              Controles de empleados, de terceros contratados y visitas. <br />
              Controles con la última tecnología en medios electrónicos.
            </Title>
            <Divider type="horizontal" />
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              id="a3"
              type="warning"
              level={3}
            >
              RURAL
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Nuestros servicios también son aplicados a zonas rurales o
              despobladas, abiertas o de grandes extensiones, utilizando la
              última metodología y tecnología en el terreno, optimizando los
              resultados.
            </Title>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              id="a4"
              type="warning"
              level={3}
              style={{ cursor: "pointer" }}
            >
              CAPACITACIONES
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Son especificas para el personal de seguridad, o abiertas para
              empleados en general, vecinos o todas aquellas personas que deseen
              interiorizarse en la materia. Son dictadas por personal altamente
              calificado con amplia experiencia en la materia.
            </Title>
            <Divider type="horizontal" />
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              id="a5"
              type="warning"
              level={3}
            >
              EVENTOS ESPECIALES
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Servicios aplicados en el ámbito concurrencia masiva, tales como
              teatros, fiestas, bailes, discotecas, etc.
            </Title>
            <Divider type="horizontal" />
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              type="warning"
              level={3}
              id="a6"
            >
              CUSTODIAS Y ACOMPAÑAMIENTOS
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              level={5}
            >
              PERSONAS, DOMICILIOS
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Se destaca por la imagen presencial, discreción y eficiencia del
              personal que la desempeña, con conocimientos adquiridos en las
              dependencias mas importantes del Estado.
            </Title>
            <Divider type="horizontal" />
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              id="a7"
              type="warning"
              level={3}
            >
              INVESTIGACIONES
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Damos las respuestas y pruebas al “querer saber” que tantas veces
              se necesitan, y que solo pueden ser obtenidas a través de nuestro
              personal calificado, en nuestro caso, con amplia experiencia,
              discreción y eficiencia, que garantizan el éxito de las misiones.
            </Title>
            <Divider type="horizontal" />
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              id="a8"
              type="warning"
              level={3}
            >
              SISTEMAS TECNOLÓGICOS
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Contamos y ofrecemos la última tecnología aplicable en materia de
              monitoreo y alarmas, lo más variado y acorde a las necesidades
              actuales (cámaras, alarmas, sensores, etc.), con el fin de obtener
              el mejor resultado en el sistema de seguridad y por consiguiente
              la tranquilidad de nuestros clientes.
            </Title>
            <Divider type="horizontal" />
            <Title
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ cursor: "pointer" }}
              id="a9"
              type="warning"
              level={3}
            >
              ASESORAMIENTOS Y AUDITORIAS
              <UpSquareOutlined
                style={{ color: "white", marginLeft: "10px" }}
              />
            </Title>
            <Title type="secondary" level={5}>
              Realizamos auditorías y asesoramiento permanente, para prevenir y
              solucionar los eventuales inconvenientes y/o imprevistos que se
              presenten durante el servicio.
            </Title>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
};

export default connect()(Services);
