import { connect } from "react-redux";
import "./about.scss";
import NavTab from "../components/NavTab";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { Col, Row, Typography } from "antd";
import Foot from "../footer/Foot";

const { Title } = Typography;

const About = () => {
  return (
    <Layout>
      <Header>
        <NavTab selected="1" />
      </Header>
      <Content>
        <Row justify="space-around" align="middle">
          <Col
            className="sas"
            style={{
              verticalAlign: "middle",
              background: "#fbfbfb",
            }}
          >
            <img
              style={{ display: "block", margin: "0 auto", width: "100%" }}
              src="icons/todo.png"
            />
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Title type="warning" level={2}>
              ¿Quiénes somos?
            </Title>
            <Title level={4}>
              "SAS Seguridad" nace por la necesidad de satisfacer la demanda de
              calidad y tecnología en materia de seguridad. <br /> Nuestra
              amplia experiencia, nos permite identificar la problemática,
              analizar las necesidades y promover soluciones personalizadas para
              cada cliente. <br />
              Un grupo de profesionales altamente calificados, conforman esta
              gran empresa privada y brindan soluciones efectivas a un mercado
              en crecimiento, que carece de una oferta integral en el ámbito
              local y nacional.
            </Title>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Title type="warning" level={2}>
              MISIÓN
            </Title>
            <Title level={4}>
              Lograr la satisfacción del cliente en términos de seguridad,
              garantizando un servicio de alto nivel, eficiente, responsable y
              comprometido.
            </Title>
            <Title type="warning" level={2}>
              VISIÓN
            </Title>
            <Title level={4}>
              “Creemos que la seguridad en todas sus facetas es un bien
              intangible, por lo que decidimos embarcarnos en este desafío para
              brindar un servicio de seguridad integral, preservando la
              privacidad y comodidad de nuestros clientes”.
            </Title>
            <Title type="warning" level={2}>
              VALORES
            </Title>
            <Title level={4}>
              EFICIENCIA, en el cumplimiento de las misiones asignadas para
              lograr el resultado buscado. <br />
              RESPONSABILIDAD, para responder en tiempo y en forma a lo
              encomendado. <br />
              DISCIPLINA, para cumplir ordenadamente con la tarea manteniendo
              regularidad y calidad.
              <br />
              COMPROMISO, por parte de todos los miembros del equipo, para que
              puedan encontrar en SAS un sentido de identificación que
              contribuya al logro de objetivos.
              <br />
              CONFIANZA, es la base de nuestra relación con clientes y
              colaboradores.
            </Title>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
};

export default connect()(About);
