import { connect } from "react-redux";
import "./contact.scss";
import NavTab from "../components/NavTab";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { Col, Divider, Row, Typography } from "antd";
import Foot from "../footer/Foot";

const { Title } = Typography;

const Contact = () => {
  return (
    <Layout>
      <Header>
        <NavTab selected="5" />
      </Header>
      <Content style={{ background: "#fbfbfb" }}>
        <Row justify="space-around" align="middle">
          <Col
            style={{
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className="sas"
          >
            <img
              width="100%"
              style={{ display: "block", margin: "30px auto" }}
              src="icons/todo.png"
            />
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col
            className="sas"
            style={{
              background: "#CAC7C6",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            <Title type="warning" style={{ fontSize: "4em" }}>
              Somos su solución en materia de seguridad
            </Title>
            <Divider type="horizontal" />
            <Title level={3}>+54 9 351 652 5724</Title>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
};

export default connect()(Contact);
