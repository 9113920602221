import React, { useEffect } from "react";
import store from "./utils/redux/Store";
import { Provider } from "react-redux";
import { Switch, Route, HashRouter as Router } from "react-router-dom";
import About from "./pages/about/About";
import Splash from "./pages/splash/Splash";
import People from "./pages/people/People";
import Customers from "./pages/customers/Customers";
import Contact from "./pages/contact/Contact";
import Services from "./pages/services/Services";
import Work from "./pages/work/Work";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Splash />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/people">
            <People />
          </Route>
          <Route path="/customers">
            <Customers />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/work">
            <Work />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
