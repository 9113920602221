import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'

//import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

//const { app, BrowserWindow } = require('electron')

/*
const path = require('path')

function createWindow() {
    const win = new BrowserWindow({
        width: 800,
        height: 600,
        autoHideMenuBar: true,
        title: 'Desktop Win ' + process.arch + ' v' + app.getVersion(),
        icon: path.join(__dirname, 'icons/win/app.ico'),
        webPreferences: {
            preload: path.join(__dirname, 'preload.js')
        }
    })
    //win.loadFile('index.html')
    win.loadURL('http://localhost:3000');
}

app.whenReady().then(() => {
    createWindow()

    app.on('activate', () => {
        if (BrowserWindow.getAllWindows().length === 0) {
            createWindow()
        }
    })
})

app.on('window-all-closed', () => {
    if (process.platform !== 'darwin') {
        app.quit()
    }
})
*/
