import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row } from "antd";
import Col from "antd/lib/grid/col";
import styled from "styled-components";
import "./splash.scss";

//import Api from '../utils/API/Api';

const Img = styled.img`
  display: block;
  margin: 10px auto;
  width: 100%;
`;

const Splash = () => {
  const history = useHistory();

  const tmp = setTimeout(() => {
    history.push("/about");
  }, 2000);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", columnGap: "10px" }}
    >
      <Col>
        <Img src="icons/super1.png" />
      </Col>
    </Row>
  );
};

export default connect()(Splash);
