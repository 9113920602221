import { connect } from "react-redux";
import "./people.scss";
import NavTab from "../components/NavTab";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { Col, Row, Typography } from "antd";
import Foot from "../footer/Foot";

const { Title } = Typography;

const People = () => {
  return (
    <Layout>
      <Header>
        <NavTab selected="2" />
      </Header>
      <Content style={{ background: "#FFF" }}>
        <Row justify="space-around" align="middle">
          <Col className="sas">
            <img
              width="100%"
              style={{ display: "block", margin: "30px auto" }}
              src="icons/people.png"
            />
            <Title level={2}>
              “Nuestro equipo está conformado por profesionales idóneos y
              expertos en seguridad”.
            </Title>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
};

export default connect()(People);
