import { connect } from "react-redux";
import { Col, Row } from "antd";
import Whatsapp from "../commons/whatsapp";

const YEAR = new Date().getFullYear();

const Foot = () => {
  return (
    <Row>
      <Col
        style={{ textAlign: "center", margin: "0 auto" }}
        md={12}
        lg={12}
        xl={8}
      >
        <img style={{ verticalAlign: "middle" }} width="5%" src="icons/3.png" />
        <span style={{ paddingLeft: "10px" }}>info@sasseguridad.com.ar</span>
      </Col>
      <Col
        style={{ textAlign: "center", margin: "0 auto" }}
        md={12}
        lg={12}
        xl={8}
      >
        <a
          target="_blank"
          style={{ color: "#333" }}
          href="https://api.whatsapp.com/send?phone=5493516525724&text=Hola, estoy interesado en los servicios de SaS Seguridad"
        >
          <Whatsapp />
          +54 9 351 652 5724
        </a>
       
      </Col>
      <Col
        style={{ textAlign: "center", margin: "0 auto" }}
        md={12}
        lg={12}
        xl={8}
      >
        SaS global Córdoba &copy; {YEAR}
      </Col>
    </Row>
  );
};

export default connect()(Foot);
