import { connect } from "react-redux";
import "./customers.scss";
import NavTab from "../components/NavTab";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import { Col, Divider, Row, Typography } from "antd";
import Foot from "../footer/Foot";

const { Title } = Typography;

const Customers = () => {
  return (
    <Layout>
      <Header>
        <NavTab selected="3" />
      </Header>
      <Content style={{ background: "#FFF" }}>
        <Row justify="space-around" align="middle">
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Title type="warning" level={3}>
              Logística DVS SRL
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              Bis Cordoba SAS
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              Rey Balthazar SRL
            </Title>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col style={{ background: "#CAC7C6" }} className="sas">
            <Title type="warning" level={3}>
              Moda Shop SRL
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              GNC Alem SA
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              Roca Bruja
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              LAM Logística Ambiental Mediterránea
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              Naldo Lombardi SA
            </Title>
            <Divider type="horizontal" />
            <Title type="warning" level={3}>
              La Angelina Salón
            </Title>
            <Divider type="horizontal" />
            <Title center type="" level={4}>
              Poder satisfacer las necesidades de los clientes en materia de
              seguridad, es para nosotros, nuestro mayor logro.
            </Title>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
};

export default connect()(Customers);
